.step-page {
    padding: 60px 0 0 105px;
    width: 100%;
    box-sizing: border-box;

    &__field {
        width: 30% !important;

        @media (max-width: 1200px) {
            width: 40% !important;
        }
        @media (max-width: 900px) {
            width: 30% !important;
        }
        @media (max-width: 700px) {
            width: 40% !important;
        }
        @media (max-width: 500px) {
            width: 48% !important;
        }
    }

    @media (max-width: 1300px) {
        padding: 40px 0 0 90px;
    }
    @media (max-width: 900px) {
        padding: 0;
    }

    &__error {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #d21212;
        margin: -11px 0 20px 0;
    }

    .custom-phone {
        padding: 0 0 0 25px;
        &.red {
            border: 1px solid #d21212;
            border-radius: 8px;
        }
        .form-control {
            width: 100%;
            border-radius: 7px;
            height: 44px;
            border: 1px solid #d2dee5;
        }

        .selected-flag {
            border: none;
        }

        .react-tel-input {
            border: none;
        }

        .selected-flag {
            display: none;
        }
        .flag-dropdown {
            border-radius: 8px 0 0 8px;
            display: none;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #091722;

        @media (max-width: 900px) {
            display: none;
        }
    }

    &__title-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #6b8899;
        margin: 16px 0 0 0;
    }

    &__subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #091722;
        margin: 0 0 16px 0;

        @media (max-width: 600px) {
            margin: 16px 0 16px 0;
        }
    }

    &__block-with-checkboxes {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1024px) {
            flex-direction: column;
        }
        @media (max-width: 900px) {
            flex-direction: row;
        }
        @media (max-width: 550px) {
            flex-direction: column;
        }
    }

    &__block {
        margin: 40px 0 0 0;
        position: relative;

        &.checkbox-container {
            display: flex;

            .label {
                min-width: 24px;
                height: 24px;
                background: white;
                border: 1px solid #d2dee5;
                margin: 0 16px 0 0;
                border-radius: 6px;
                cursor: pointer;

                &.checked {
                    border: 1px solid #e9a631;
                    background: #e9a631;
                }

                .checkbox {
                    display: none;
                    position: relative;

                    &__mark {
                        position: absolute;
                        width: 15px;
                        top: 8px;
                        left: 5px;
                    }
                }
            }
        }

        @media (max-width: 600px) {
            margin: 0px 0 0 0;
        }
    }

    &__column {
        width: 48%;
        margin: 0 8px 0 0;
        &:last-child {
            margin: 0;
        }
        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    &__line {
        border-bottom: 1px solid #d2dee5;
        margin: 30px 0 0 0;
        position: absolute;
        width: 100%;
        top: 33px;
        z-index: -1;
    }

    &__count {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__number {
        min-width: 44px;
        height: 44px;
        background: #ffffff;
        border: 1px solid #d2dee5;
        border-radius: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #091722;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            border: 1px solid #6b8899;
            transition: all 0.3s;
        }

        &.active {
            border: 1px solid #e9a631;
            transition: all 0.3s;
        }
    }

    &__prop-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 1040px) {
            flex-direction: column;
        }
        @media (max-width: 900px) {
            flex-direction: row;
        }
        @media (max-width: 650px) {
            flex-direction: column;
        }
    }

    &__prop {
        margin: 34px 16px 0 0;
        width: 46%;
        @media (max-width: 1040px) {
            width: 100%;
        }
        @media (max-width: 900px) {
            width: 46%;
        }
        @media (max-width: 650px) {
            width: 100%;
        }
    }

    .phone-image {
        position: absolute;
        top: 51px;
        left: 16px;
        width: 22px;
    }
}
