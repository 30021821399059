.homepage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
        font-weight: 700;
        font-size: 90px;
        line-height: 99%;
        text-transform: uppercase;
        padding: 55px 0;
        border-bottom: 1px solid #d2dee5;

        @media (max-width: 1185px) {
            font-size: 70px;
        }
        @media (max-width: 717px) {
            font-size: 55px;
            padding: 55px 0;
        }
        @media (max-width: 600px) {
            padding: 96px 0 40px 0;
        }
        @media (max-width: 550px) {
            font-size: 48px;
        }
        @media (max-width: 450px) {
            font-size: 44px;
        }
    }

    &__row {
        display: flex;
        height: 422px;
        border-bottom: 1px solid #d2dee5;

        @media (max-width: 950px) {
            flex-direction: column;
            align-items: flex-start;
        }
        @media (max-width: 620px) {
            height: auto;
            padding: 0 0 40px 0;
        }
    }

    &__text {
        width: 70%;
        padding: 60px 120px 0 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #091722;
        border-right: 1px solid #d2dee5;

        @media (max-width: 1124px) {
            padding: 50px 30px 0 0;
        }
        @media (max-width: 950px) {
            border-right: none;
            padding: 40px 0;
            width: 90%;
        }
        @media (max-width: 700px) {
            border-right: none;
            padding: 40px 0 20px 0;
            width: 100%;
        }
        @media (max-width: 500px) {
            font-size: 18px;
        }
    }

    .btn-block {
        width: 30%;
        padding: 60px 0 0 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width: 1124px) {
            padding: 50px 0 0 30px;
        }
        @media (max-width: 950px) {
            padding: 0;
            width: 286px;
        }
        @media (max-width: 450px) {
            width: 100%;
        }
        &__title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            color: #091722;
            padding: 0 0 8px 0;
            width: 285px;
            @media (max-width: 1110px) {
                width: 245px;
            }
            @media (max-width: 950px) {
                width: 285px;
            }
            @media (max-width: 450px) {
                width: 100%;
            }
        }
        &__btn {
            background: #e9a631;
            border-radius: 8px;
            color: #ffffff;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            width: 285px;
            height: 60px;
            align-items: center;
            justify-content: center;
            display: flex;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                background: #f2b13f;
                transition: all 0.3s;
            }

            &.disabled {
                background: #f4d298;
            }

            @media (max-width: 1110px) {
                width: 245px;
                font-size: 18px;
            }
            @media (max-width: 950px) {
                width: 285px;
                font-size: 20px;
            }
            @media (max-width: 450px) {
                width: 100%;
            }

            img {
                padding: 0 0 0 8px;
                width: 20px;
            }
        }
    }

    &__reclame {
        padding: 22px 10px;
        width: 100%;

        @media (max-width: 620px) {
            padding: 40px 10px;
        }
        @media (max-width: 500px) {
            padding: 40px 0px;
        }
    }
}
