.loader-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.loader {
    &__text {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */

        color: #000000;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100px;

        @media (max-width: 600px) {
            position: fixed;
            top: 43%;
        }
    }
}

img.loader {
    animation: 1s linear 0s normal none infinite running rot;
    -webkit-animation: 1s linear 0s normal none infinite running rot;
    width: 45px;
}
@keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
