.privacy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #091722;

    &__wrap {
        @media (max-width: 600px) {
            margin: 50px 0 0 0;
        }
    }

    &__nav {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        padding: 40px 0 0 0;
        color: #6b8899;
        display: flex;

        div {
            padding: 0 5px;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 64px;
        line-height: 99%;
        text-transform: uppercase;
        color: #091722;
        padding: 20px 0 60px 0;
        border-bottom: 1px solid #d2dee5;

        @media (max-width: 750px) {
            font-size: 50px;
            padding: 40px 0;
        }
        @media (max-width: 550px) {
            font-size: 34px;
            padding: 20px 0;
        }
    }

    &__main {
        padding: 60px 0;
        width: 83%;

        @media (max-width: 850px) {
            padding: 40px 0;
            width: 100%;
        }
        @media (max-width: 750px) {
            padding: 20px 0;
        }
        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #091722;

            @media (max-width: 750px) {
                font-size: 14px;
            }
        }
    }

    &__subtitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #091722;
    }

    &__block {
        margin: 0 0 24px 0;
    }
}
