.header {
    border-bottom: 1px solid #d2dee5;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__x {
        width: 24px;
    }

    &__logo {
        width: 185px;
        &.active {
            cursor: pointer;
        }
    }

    &__wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__leave {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #6b8899;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 78px;

        cursor: pointer;
    }
}

.header-phone {
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 5;

    &.hide {
        height: 10px !important;
    }

    &__wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #091722;
        width: 100%;
        text-align: center;
        height: 25px;

        @media (max-width: 550px) {
            font-size: 16px;
        }
    }

    &__leave {
        height: 25px;
        cursor: pointer;
    }
}
