.slider {
    margin: 0 0 36px 0;
    position: relative;

    &__title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #091722;
        height: 45px;
        display: flex;
        align-items: center;

        @media (max-width: 400px) {
            font-size: 14px;
        }
    }

    &__line {
        height: 3px;
        border-bottom: 2px solid #e9a631;
        position: absolute;
        top: 68px;
        left: 2px;
        z-index: 2;
    }
    &__line-grey {
        width: 100%;
        height: 3px;
        position: absolute;
        top: 68px;
        left: 2px;
        border-bottom: 2px solid #d2dee5;
    }
    @-moz-document url-prefix() {
        &__line-grey {
            z-index: -2;
        }
        &__line {
            z-index: -1;
        }
    }
    @-moz-document url-prefix() {
        &__line-grey {
            // top: 65px;
        }
    }

    &__button {
        cursor: pointer;
        font-size: 20px;
        padding: 0 15px;
    }

    &__range {
        width: 100%;
    }
    .horizontal-slider {
        width: 100%;
        height: 35px;
    }

    .example-track {
        position: relative;
        background: #e9a631;
    }

    .example-track.example-track-1 {
        background: #d2dee5;
    }

    .horizontal-slider .example-track {
        top: 20px;
        height: 3px;
    }

    .horizontal-slider .example-thumb {
        top: 8px;
        outline: none;
    }

    &__run {
        top: 10px;
        position: absolute;
    }

    &__values {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 10px 0;
    }

    &__value {
        display: flex;
        align-items: center;
        height: 45px;
    }

    &__number {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #091722;
        padding: 0 0 0 6px;
    }

    &__item {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #6b8899;
    }

    &__dollar {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #e9a631;
    }
}

.slider__range {
    -webkit-appearance: none;
    width: 101%;
    height: 3px;
    border-radius: 5px;
    outline: none;
}

.slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 48px;
    height: 24px;
    background: #e9a631;
    border-radius: 100px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    box-shadow: none;

    &:hover {
        transition: all 0.3s;
        box-shadow: 0px 0px 0px 6px #f8e4c1;
    }
}
.slider__range::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 44px;
    height: 22px;
    background: #e9a631;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: none;

    &:hover {
        transition: all 0.3s;
        box-shadow: 0px 0px 0px 6px #f8e4c1;
    }
}
