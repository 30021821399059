.custom-selection {
  position: relative;
  width: 174px;

  &__error {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #d21212;
    margin: 12px 0 0 0;
  }
  &__field {
    width: 175px;
    height: 44px;
    padding: 0 0 0 16px;
    background: #ffffff;
    border: 1px solid #d2dee5;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #091722;
    outline: none;

    &.focus {
      border: 1px solid #6b8899 !important;
    }
    &.hover {
      border: 1px solid #6b8899 !important;
    }

    &.error {
      border: 1px solid #d21212;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6b8899;
    }
  }

  &__options {
    position: absolute;
    width: 175px;
    height: 252px;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(29, 29, 29, 0.07),
      0px 1px 20px rgba(29, 29, 29, 0.08);
    border-radius: 8px;
    overflow-y: scroll;
    top: 53px;
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #091722;
    padding: 8px 17px;

    &:hover {
      background: #f0f4f7;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  &__arrow {
    position: absolute;
    top: 12px;
    left: 148px;
    transform: rotate(-90deg);
    color: #6b8899;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s;

    &.open {
      transform: rotate(90deg);
      transition: all 0.3s;
    }
  }

  &__options::-webkit-scrollbar {
    width: 5px; /* ширина всей полосы прокрутки */
    padding: 0 5px;
  }

  &__options::-webkit-scrollbar-track {
    background: #f0f4f7; /* цвет зоны отслеживания */
  }

  &__options::-webkit-scrollbar-thumb {
    background-color: #d2dee5; /* цвет бегунка */
    height: 40%;
    border-radius: 20px; /* округлось бегунка */
  }
}
