.layout {
    &__mark {
        width: 9px;
    }
    &__container {
        @media (max-width: 900px) {
            flex-direction: column;
        }
    }
    &__background {
        height: 95px;
        position: fixed;
        width: 100%;
        background: white;
        top: 51px;
        z-index: 4;
    }
    &__wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__line {
        position: absolute;
        width: 126%;
        border-top: 1px solid #d2dee5;
        /* top: 0; */
        bottom: 0;
        left: -49px;
    }

    &-nav {
        width: 40%;
        box-sizing: border-box;
        padding: 60px 120px 60px 0;
        border-right: 1px solid #d2dee5;

        @media (max-width: 1300px) {
            padding: 40px 90px 40px 0;
        }
        @media (max-width: 900px) {
            border: none;
            width: 100%;
            padding: 0px 0 30px 0;
        }

        @media (max-width: 600px) {
            padding: 0px 0 16px 0;
            background-color: white;
            position: fixed;
            z-index: 5;
            width: 87%;
            top: 10px;
        }
        @media (max-width: 550px) {
            width: 88%;
        }
        @media (max-width: 316px) {
            width: 92%;
        }
        @media (max-width: 302px) {
            width: 93%;
        }
        @media (max-width: 297px) {
            width: 95%;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #091722;
        margin: 0 0 20px 0;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6b8899;
    }

    &__line-steps {
        position: absolute;
        height: 98%;
        border-right: 1px solid #d2dee5;
        top: 0;
        left: -33px;

        @media (max-width: 900px) {
            width: 99%;
            border-bottom: 1px solid #d2dee5;
            height: 0;
            left: 0;
            top: 30px;
        }
    }

    &__line-steps-active {
        position: absolute;
        border-right: 2px solid #e9a631;
        top: 0;
        left: -33px;

        @media (max-width: 900px) {
            left: 0;
            top: 30px;
            border-bottom: 2px solid #e9a631;
            border-right: none;
        }
    }

    &__steps-wrap {
        @media (max-width: 900px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }

    &__steps {
        position: relative;
        margin: 40px 0 40px 40px;

        @media (max-width: 900px) {
            padding: 20px 0;
            margin: 0;
            display: flex;
        }
    }

    .step {
        position: relative;
        margin: 0 0 50px 0;

        @media (max-width: 1100px) {
            margin: 0 0 50px 0;
        }
        @media (max-width: 900px) {
            margin: 0;
        }

        &__extra {
            position: absolute;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #e9a631;
            top: -15px;
        }

        &__circel {
            position: absolute;
            border: 1px solid #d2dee5;
            width: 20px;
            height: 20px;
            top: 0;
            border-radius: 50%;
            background: white;
            left: -44px;

            @media (max-width: 900px) {
                position: relative;
                left: 0;
            }

            &.active {
                border: 2px solid #e9a631;
            }

            &.done {
                border: 2px solid #e9a631;
                background: #e9a631;
                color: white;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #6b8899;

            &.active {
                color: #091722;
            }

            &.done {
                color: #091722;
            }
        }
    }

    .wrap {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        &__title {
            color: #6b8899;
        }

        &__value {
            color: #091722;
            padding: 0 0 0 10px;
        }
    }
}
