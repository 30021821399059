.calc {
    box-sizing: border-box;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 600px) {
        padding: 110px 0 0 0;
    }

    &__btn-wrap {
        display: flex;
        margin: 0 0 45px 0;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0;

        @media (max-width: 500px) {
            width: 100%;
        }
    }

    &__next {
        background: #e9a631;
        border-radius: 8px;
        padding: 1px 25px;
        font-weight: 500;
        min-width: 121px;
        font-size: 16px;
        min-height: 42px;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;

        @media (max-width: 500px) {
            width: 48%;
            padding: 0;
        }

        &:hover {
            background: #f2b13f;
            transition: all 0.3s;
        }

        &.disabled {
            background: #f4d298;
            cursor: context-menu;
        }
    }

    &__check {
        background: #e9a631;
        border-radius: 8px;
        width: 215px;
        font-weight: 500;
        min-width: 121px;
        font-size: 16px;
        min-height: 42px;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;

        @media (max-width: 425px) {
            width: 200px;
        }

        &:hover {
            background: #f2b13f;
            transition: all 0.3s;
        }

        &.disabled {
            background: #f4d298;
            cursor: context-menu;
        }
    }

    &__prev {
        background: #e6eef2;
        border-radius: 8px;
        width: 160px;
        height: 44px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #091722;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 16px 0 0;
        transition: all 0.3s;

        @media (max-width: 500px) {
            width: 48%;
        }

        &.withCheck {
            @media (max-width: 425px) {
                width: 35%;
            }
        }

        &:hover {
            background: #dce7ed;
            transition: all 0.3s;
        }
    }
}
