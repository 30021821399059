.text-field {
    font-family: 'Poppins';
    font-style: normal;
    position: relative;
    width: 100%;
    margin: 0 0 24px 0;

    &.text-field-prop {
        margin: 0 0 16px 0;
    }

    &__error {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #d21212;
        margin: 12px 0 0 0;
    }

    &.phone {
        min-height: 77px;
    }

    .placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #6b8899;
        position: absolute;
        top: 11px;
        left: 15px;
        transition: all 0.3s;
        z-index: -1;

        &.phone {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: black;
            position: absolute;
            top: 0;
            left: 47px;
            transition: all 0.3s;
            z-index: -1;
            padding: 0;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.focus {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: black;
                position: absolute;
                top: 0;
                left: 47px;
                transition: all 0.3s;
                z-index: -1;
                padding: 0;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.focus {
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #6b8899;
            position: absolute;
            top: -11px;
            left: 21px;
            background: white;
            transition: all 0.3s;
            z-index: 1;
            padding: 0 3px;
        }
    }
    &__input {
        width: 100%;
        height: 44px;
        background: transparent;
        border: 1px solid #d2dee5;
        border-radius: 8px;
        padding: 0 40px 0 16px;
        box-sizing: border-box;
        outline: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #091722;

        &.focus {
            border: 1px solid #6b8899;
        }

        &.error {
            border: 1px solid #d21212;
        }
    }
    .custom-phone {
        padding: 0 0 0 30px;
    }

    &__dollar {
        position: absolute;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #6b8899;
        top: 9px;
        right: 16px;
    }
}

.phone-input {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #091722;
    padding: 1px 40px 0 88px;

    &.red {
        border: 1px solid #d21212;
    }
}
