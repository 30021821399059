.footer {
    background: #2c343a;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .footer-container {
        width: 1540px;
        padding: 0 120px;
        @media (max-width: 1440px) {
            width: 1200px;
            padding: 0 40px;
        }

        @media (max-width: 550px) {
            padding: 0 30px;
        }
        @media (max-width: 500px) {
            padding: 0 24px;
        }
    }

    .row {
        display: flex;
        padding: 20px 0 27px 0;
        border-bottom: 1px solid #515354;

        &__text-wrap {
            @media (max-width: 900px) {
                display: flex;
                flex-wrap: wrap;
            }
        }

        @media (max-width: 830px) {
            flex-direction: column;
        }
        &__text {
            display: flex;
            flex-wrap: wrap;

            width: 100%;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #95999d;
            padding: 10px 60px 0 0;

            &-item {
                padding: 4px 0px;
            }

            @media (max-width: 1100px) {
                padding: 10px 30px 0 0;
            }
            @media (max-width: 830px) {
                width: 100%;
                padding: 0;
            }

            &-btn {
                display: inline;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #ffffff;
                cursor: pointer;
            }
        }

        .info {
            width: 35%;
            padding: 10px 0 0 60px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-transform: uppercase;

            @media (max-width: 1100px) {
                padding: 10px 0 0 30px;
            }
            @media (max-width: 830px) {
                width: 100%;
                padding: 20px 0 0 0;
            }

            &__item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 0 0 56px 0;

                @media (max-width: 830px) {
                    margin: 0 0 10px 0;
                }
            }

            &__title {
                font-size: 14px;
                color: #ffffff;
                opacity: 0.5;
                padding: 0 0 8px 0;
                @media (max-width: 830px) {
                    padding: 0;
                }
            }
            &__value {
                font-size: 16px;
                text-align: center;
                color: #ffffff;

                @media (max-width: 1000px) {
                    font-size: 14px;
                }
            }
        }

        .links {
            width: 35%;
            padding: 0 0 4px 60px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            @media (max-width: 1000px) {
                width: 100%;
                padding: 0 0 4px 20px;
                justify-content: flex-end;
            }
            @media (max-width: 830px) {
                justify-content: flex-start;
                padding: 20px 0 4px 0;
            }

            &__link {
                width: 112px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                opacity: 0.5;
                cursor: pointer;

                @media (max-width: 1000px) {
                    padding: 0 20px 0 0;
                }
                @media (max-width: 830px) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
    }
}
