.calendar-page {
  width: 585px;

  @media (max-width: 834px) {
    width: 435px;
  }
  @media (max-width: 550px) {
    width: 358px;
  }

  &__date-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #353a3e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    border-top: 1px solid #d2dee5;
    padding: 10px 0;
  }

  &__title {
    color: #091722;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0.24px;
  }

  &__day-title {
    color: #353a3e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 17px 0 8px 0;

    @media (max-width: 834px) {
      display: none;
    }
  }

  &__btn {
    display: flex;
    height: 44px;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #e9a631;
    color: #fff;
    text-align: center;
    font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
    text-transform: capitalize;
    margin: 24px 0 0 0;
    cursor: pointer;
  }
}

.title {
  color: #353a3e;
  font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0 0 8px 0;
}

.calendar {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #d2dee5;
  background: #fff;
  padding: 0 42px 0 0;

  @media (max-width: 834px) {
    margin: 0 0 25px 0;
  }

  @media (max-width: 550px) {
    display: block;
    padding: 0;
    max-width: 358px;
  }

  &__hour {
    color: #353a3e;
    font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    padding: 4px 16px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #f4f5f6;
    }

    &.active {
      color: #0076bc;
      font-weight: 600;
    }
  }

  &__day {
    padding: 0 15px 5px 15px;
    color: #353a3e;
    font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
  }

  &__hours {
    width: 156px;
    padding: 28px 11px 18px 11px;
    min-height: 337px;
    border-right: 1px solid #d2dee5;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    @media (max-width: 834px) {
      display: none;
    }
  }
}

.react-datepicker__month-container {
  width: 100%;
  border-radius: 8px;
  //   border: 1px solid #d2dee5;
  background: #fff;
  padding: 20px;

  @media (max-width: 550px) {
    width: 89%;
  }
  @media (max-width: 400px) {
    width: 96%;
  }
}

.react-datepicker {
  border: none !important;
  width: 100%;

  @media (max-width: 550px) {
    width: 92%;
  }
}

.react-datepicker__header {
  background-color: white !important;
  border: none !important;
}

.react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.current-month {
  color: #001965 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0 !important;
}

.arrow-calendar-left {
  margin: 0 0 0 28px;
  cursor: pointer;
}

.arrow-calendar-right {
  margin: 0px 28px 0 0;
  cursor: pointer;
  transform: rotate(180deg);
}

.current-month__month {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #353a3e;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.datepicker__arrows-right {
  transform: rotate(180deg);
}

.react-datepicker__day-names {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.react-datepicker__day-name {
  color: var(--text-icons-label, #6b8899);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

.react-datepicker__day {
  color: var(--text-icons-primary, #091722);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  width: 42px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day:hover {
  border-radius: 4px;
  background: #f0f4f7;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.react-datepicker__day--today {
  color: #0076bc !important;
}

.react-datepicker__day--selected {
  border-radius: 4px;
  border: 1px solid var(--text-icons-label, #6b8899);
  background: #f0f4f7;
  color: var(--text-icons-primary, #091722);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  box-sizing: border-box;
}

// .react-datepicker__day--keyboard-selected {
//   background-color: #001965 !important;
//   border: 1px solid #001965;
//   color: white !important;
//   border-radius: 50% !important;
// }

.react-datepicker__day--keyboard-selected {
  background-color: white !important;
}

.react-datepicker__day--disabled {
  color: #b3bad1 !important;
}
.react-datepicker__day--disabled:hover {
  color: #b3bad1 !important;
  cursor: context-menu;
  background-color: white !important;
}

.calendar-success {
  border-radius: 8px;
  border: 1px solid #d2dee5;
  width: 488px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 0 0 0;

  @media (max-width: 550px) {
    width: 347px;
  }

  &__text {
    margin: 16px 0 0 0;
    width: 385px;
    text-align: center;
    color: #091722;
    text-align: center;
    font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__dataText {
    color: #091722;
    font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
