body {
  margin: 0;
  font-family: 'Poppins', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  width: 1540px;
  padding: 0 120px;
}
@media (max-width: 1440px) {
  .container {
    width: 1200px;
    padding: 0 40px;
  }
}

@media (max-width: 550px) {
  .container {
    padding: 0 30px;
  }
}
@media (max-width: 490px) {
  .container {
    padding: 0 24px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
  }
}

.rotate-small-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.small-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #e9a631;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
