.checkbox {
    border: 1px solid #d2dee5;
    border-radius: 8px;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    margin: 0 0 8px 0;
    cursor: pointer;
    transition: all 0.3s;

    @media (max-width: 900px) {
        &:hover {
            border: none;
        }
    }

    &:hover {
        border: 1px solid #6b8899;
        transition: all 0.3s;
    }

    &.checked {
        border: 1px solid #e9a631;
        transition: all 0.3s;
    }

    &__checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    label {
        border: 1px solid #d2dee5;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin: 0 12px;
        cursor: pointer;
    }

    .checked {
        border: 7px solid #e9a631;
        width: 8px;
        height: 8px;
    }
}
