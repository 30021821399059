.modal-wrapper {
    width: 100%;
    height: 100%;
    background: rgba(3, 17, 26, 0.2);
    backdrop-filter: blur(8px);
    position: fixed;
    top: 0;
    z-index: 6;
}

.modal {
    width: 600px;
    height: 296px;
    position: fixed;
    background: #ffffff;
    border-radius: 12px;
    z-index: 7;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 28px 40px;
    box-sizing: border-box;

    @media (max-width: 800px) {
        width: 327px;
        height: 326px;
        padding: 28px 25px;
    }

    .top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 48px 0;

        @media (max-width: 800px) {
            margin: 0 0 30px 0;
        }

        &__title {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #000000;
        }

        &__close {
            width: 25px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.2);
                transition: all 0.3s;
            }
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #353a3e;
    }

    &__line {
        width: 100%;
        position: absolute;
        top: 65%;
        left: 0;
        border-top: 1px solid #d2dee5;

        @media (max-width: 800px) {
            top: 55%;
        }
    }

    &__btn {
        border-radius: 8px;
        width: 50%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        cursor: pointer;

        &:first-child {
            margin: 0 16px 0 0;
        }

        @media (max-width: 800px) {
            width: 100%;
        }
    }

    .btns {
        display: flex;
        justify-content: space-around;
        margin: 70px 0 30px 0;

        @media (max-width: 800px) {
            flex-direction: column;
            margin: 20px 0 30px 0;
        }

        &__cancel {
            background: #e6eef2;
            color: #091722;
            @media (max-width: 800px) {
                margin-bottom: 8px;
                margin-top: 24px;
            }
        }

        &__leave {
            background: #d21212;
            color: #ffffff;
            margin-bottom: 24px;
        }
    }
}
