.container-congratulations {
  display: flex;
  width: 1540px;
  padding: 0 120px;

  @media (max-width: 1440px) {
    width: 1200px;
    padding: 0 40px;
  }

  @media (max-width: 1000px) {
    padding: 0 30px;
  }
}

.congratulations {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__a {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 0 0 86px 0;

    @media (max-width: 1300px) {
      justify-content: flex-end;
      margin: 0px 0 -115px 0;
      width: 100%;
      padding: 0;
    }
    @media (max-width: 1000px) {
      justify-content: flex-end;
      margin: 0 0 -440px 0;
      width: 100%;
    }
    @media (max-width: 460px) {
      margin: 0 0 -524px 0;
    }
  }

  .btn-block {
    width: 63%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -37px 0 0 0;
    padding: 0 0 0 25px;

    @media (max-width: 1300px) {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 520px) {
      width: 100%;
      padding: 0;
    }

    &__subtext {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6b747a;
      margin: 20px 0 0 0;
      width: 507px;

      @media (max-width: 1440px) {
        width: 387px;
      }
      @media (max-width: 1200px) {
        width: 333px;
      }
      @media (max-width: 1000px) {
        width: 100%;
        margin: 20px 0 20px 0;
      }
    }

    &__text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #091722;
      width: 387px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 16px 0;

      @media (max-width: 1000px) {
        width: 100%;
        margin: 16px 0 0 0;
      }
    }

    &__btn {
      width: 507px;
      height: 60px;
      background: #e9a631;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
      text-decoration: none;

      @media (max-width: 1440px) {
        width: 387px;
      }
      @media (max-width: 1200px) {
        width: 333px;
      }
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }

  &__main-wrap {
    display: flex;
    border-bottom: 1px solid #d2dee5;
    padding: 0 0 40px 0;

    @media (max-width: 1300px) {
      flex-direction: column;
    }
  }

  &__main-block {
    width: 60%;
    padding: 0 25px 0 0;

    @media (max-width: 1300px) {
      width: 85%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
    @media (max-width: 520px) {
      width: 94%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    font-size: 48px;
    line-height: 99%;
    text-transform: uppercase;
    color: #091722;
    width: 100%;
    margin: 60px 0 0 0;

    &-img {
      position: relative;
    }

    @media (max-width: 900px) {
      font-size: 45px;
      margin: 40px 0 0 0;
    }

    @media (max-width: 600px) {
      font-size: 40px;
      padding: 54px 0 0 0;
    }
    @media (max-width: 460px) {
      font-size: 30px;
    }
  }

  &__sun {
    width: 38px;
    height: 38px;

    @media (max-width: 900px) {
      width: 34px;
      height: 34px;
    }

    @media (max-width: 600px) {
      width: 30px;
      height: 30px;
    }
    @media (max-width: 460px) {
      width: 23px;
      height: 23px;
    }
  }
  &__mark {
    position: absolute;
    height: 12px;
    top: 33%;
    left: 29%;

    @media (max-width: 900px) {
      height: 10px;
      top: 37%;
      left: 31%;
    }

    @media (max-width: 600px) {
      height: 10px;
      top: 36%;
      left: 28%;
    }
    @media (max-width: 460px) {
      height: 7px;
      top: 36%;
      left: 31%;
    }
  }

  &__subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #091722;
    margin: 40px 0 0 0;
    width: 98%;

    @media (max-width: 1504px) {
      font-size: 20px;
    }
    @media (max-width: 1440px) {
      font-size: 20px;
      width: 98%;
    }
    @media (max-width: 1200px) {
      font-size: 18px;
      line-height: 27px;
      width: 100%;
    }
  }

  .row-top {
    display: flex;
    padding: 40px 0 40px 0;

    &-2D {
      flex-direction: column !important;
    }

    &-2E {
      padding: 240px 0 40px 0;
    }

    &__e-title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #091722;
      margin: 0 0 24px 0;
    }

    &__e-subtitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      color: #000000;
      display: flex;
      margin: 8px 0;
    }

    &__e-minus {
      min-width: 20px;
      max-width: 20px;
      height: 20px;
      color: white;
      background: #d21212;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 18px;
      margin: 0 16px 0 0;
    }

    &__e-plus {
      min-width: 20px;
      max-width: 20px;
      height: 20px;
      color: white;
      background: #007f5f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 18px;
      margin: 0 16px 0 0;

      img {
        height: 6px;
      }
    }

    .item {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      color: #000000;
      display: flex;
      padding: 8px 0;

      &__mark {
        background: #e9a631;
        border: 2px solid #e9a631;
        border-radius: 50%;
        min-width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px 0 0;

        img {
          width: 8px;
        }
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      padding: 40px 0 40px 0;
    }

    &__text {
      width: 90%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 156%;
      color: #6b747a;
      padding: 0 60px 24px 0;

      .e {
        @media (max-width: 1000px) {
          padding: 40px 0;
        }
      }

      @media (max-width: 1300px) {
        padding: 28px 60px 24px 0;
      }

      @media (max-width: 1000px) {
        width: 100%;
        margin: 24px 0 0 0;
        padding: 0 0 24px 0;
      }
      @media (max-width: 800px) {
        font-size: 14px;
        margin: 24px 0 24px 0;
      }
    }
  }

  .row-bottom {
    display: flex;
    padding: 40px 0 40px 0;

    @media (max-width: 1000px) {
      flex-direction: column;
      width: 720px;
      padding: 40px 0 0 0;
    }
    @media (max-width: 800px) {
      width: 100%;
    }

    .wrapper {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 60px 0 0;
      justify-content: space-between;
      height: 346px;

      @media (max-width: 1440px) {
        height: 283px;
      }
      @media (max-width: 1000px) {
        height: auto;
      }

      &__wrap {
        @media (max-width: 1000px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 720px;
        }
        @media (max-width: 800px) {
          width: 100%;
        }
      }

      @media (max-width: 1000px) {
        width: 100%;
        padding: 0;
      }

      &__title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        text-transform: uppercase;
        color: #091722;

        @media (max-width: 800px) {
          font-size: 24px;
        }
      }

      &__subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 16px 0 0 0;
        color: #6b747a;

        @media (max-width: 800px) {
          font-size: 14px;
        }
      }
    }

    &__frame {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      @media (max-width: 1000px) {
        width: 885px;
      }
      @media (max-width: 950px) {
        width: 800px;
      }
      @media (max-width: 850px) {
        width: 670px;
      }
      @media (max-width: 725px) {
        width: 570px;
      }
      @media (max-width: 625px) {
        width: 470px;
      }
      @media (max-width: 530px) {
        width: 397px;
      }
      @media (max-width: 450px) {
        width: 318px;
      }

      iframe {
        width: 507px;
        height: 288px;
        border-radius: 8px;
        @media (max-width: 1440px) {
          width: 387px;
          height: 220px;
        }
        @media (max-width: 1200px) {
          width: 333px;
          height: 189px;
        }
        @media (max-width: 1000px) {
          width: 100%;
          height: 495px;
          margin: 40px 0 0 0;
        }

        @media (max-width: 950px) {
          height: 427px;
        }
        @media (max-width: 850px) {
          height: 375px;
        }
        @media (max-width: 725px) {
          height: 320px;
        }
        @media (max-width: 625px) {
          height: 265px;
        }
        @media (max-width: 530px) {
          height: 225px;
        }
        @media (max-width: 450px) {
          height: 181px;
        }
      }
    }
  }
}

.ytp-large-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  background: white;
  border-radius: 50%;
  margin-left: -34px;
  margin-top: -24px;
  -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  z-index: 63;
}

.attention {
  margin: 16px 0 0 0;
  background: #f4f4f4;
  border-radius: 8px;
  display: flex;
  padding: 12px;
  @media (max-width: 1000px) {
    width: 97%;
    margin: 16px 0 40px 0;
  }
  @media (max-width: 600px) {
    width: 94%;
  }

  &__i {
    color: #1c75bc;
    margin: 4px 8px 0 0;
    min-width: 19px;
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #1c75bc;
    border-radius: 50%;
  }
  &__text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 156%;
    color: #091722;

    @media (max-width: 650px) {
      font-size: 12px;
    }
  }
}
