.err-page {
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 1200px;
        padding: 0 120px;

        @media (max-width: 1200px) {
            padding: 0 40px;
        }
        @media (max-width: 550px) {
            padding: 0 30px;
        }
        @media (max-width: 490px) {
            padding: 0 15px;
        }
    }

    .error {
        background: rgba(210, 18, 18, 0.1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 107px;
        height: 40px;
        margin: 131px 0 16px 0;
        color: #d21212;
        font-weight: 500;
        font-size: 16px;
        line-height: 99%;
        letter-spacing: 0.06em;

        @media (max-width: 700px) {
            font-size: 10px;
            width: 68px;
            height: 25px;
        }

        &__mark {
            width: 20px;
            height: 20px;
            border: 2px solid #d21212;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 700px) {
                width: 12px;
                height: 12px;
                border: 1px solid #d21212;
            }
        }
    }

    &__img {
        margin: 75px 0 0 0;
        width: 70%;
    }

    &__img-access {
        width: 51%;

        @media (max-width: 900px) {
            width: 80%;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 32px;
        line-height: 128%;
        color: #091722;
        margin: 34px 0 0 0;
        text-align: center;

        @media (max-width: 1200px) {
            font-size: 30px;
        }
        @media (max-width: 1000px) {
            font-size: 28px;
            width: 80%;
        }
        @media (max-width: 800px) {
            font-size: 24px;
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        width: 426px;
        color: #6b8899;
        margin: 16px 0 0 0;

        @media (max-width: 650px) {
            font-size: 14px;
            width: 80%;
        }
    }

    &__btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background: #e6eef2;
        border-radius: 8px;
        width: 183px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0 0 0;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            transition: all 0.3s;
            background: #dce7ed;
        }
    }
}
